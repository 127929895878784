import React from "react";
import SEO from "../components/SEO";
import TitleSection from "@src/components/partials/training/TitleSection";
import FooterSection from "@src/components/partials/training/FooterSection";
import CtasSection from "@src/components/partials/training/CtasSection";
import UpcomingSection from "@src/components/partials/training/UpcomingTraining";
import EpisodesSection from "@src/components/partials/training/EpisodesSection";
import { useStaticQuery, graphql } from "gatsby";

const TrainingPage = () => {
  const data = useStaticQuery(graphql`
    {
      youtube: file(name: { eq: "youtube" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      twitter: file(name: { eq: "twitter" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      certificate: file(name: { eq: "certificate" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      contact: file(name: { eq: "contact" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      community: file(name: { eq: "community" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      resources: file(name: { eq: "resources" }) {
        childImageSharp {
          gatsbyImageData(width: 90, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      epBlob1: file(name: { eq: "epBlob1" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      epBlob2: file(name: { eq: "epBlob2" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      epBlob3: file(name: { eq: "epBlob3" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      epBlob4: file(name: { eq: "epBlob4" }) {
        childImageSharp {
          gatsbyImageData(width: 65, quality: 90, placeholder: NONE, layout: FIXED)
        }
      }
      directus {
        Page_District_Launch {
          section
          page_heading
          page_description
        }
      }
    }
  `);

  const {
    directus: {
      Page_District_Launch: { page_heading, page_description, section },
    },
  } = data;
  return (
    <>
      <SEO
        title="ClassDojo for Districts Launch Kit"
        description="
Helpful resources to launch successfully in your communities 🚀"
      />
      <TitleSection
        data={{
          page_heading,
          page_description,
        }}
      />
      {section.map((s, index) => {
        return (
          <EpisodesSection key={index} data={s} index={index + 1} i18nPrefix="directus.page_district_launch.section" />
        );
      })}
    </>
  );
};
export default TrainingPage;
